import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { authStateSelector } from 'store/auth/selector'

const withAuth = Page => {
  const AuthenticatedWrapper = () => {
    const authState = useSelector(authStateSelector)
    useEffect(() => {
      if (!authState.isAuthenticated) {
        navigate('/login')
        return
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isAuthenticated])
    if (!authState.isAuthenticated) return null
    return <Page />
  }
  return AuthenticatedWrapper
}

export default withAuth
