import { gql } from '@apollo/client'

export const GET_ORDERS = gql`
  query GET_ORDERS($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      orders(first: 20) {
        edges {
          node {
            id
            orderNumber
            processedAt
            financialStatus
            name
            fulfillmentStatus
            totalPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

export const GET_ORDER = gql`
  query GET_ORDER($orderId: ID!) {
    node(id: $orderId) {
      ... on Order {
        id
        orderNumber
        financialStatus
        fulfillmentStatus
        processedAt
        subtotalPriceV2 {
          amount
        }
        currentTotalPrice {
          amount
        }
        totalPriceV2 {
          amount
        }
        successfulFulfillments {
          trackingCompany
          trackingInfo {
            number
            url
          }
          fulfillmentLineItems(first: 100) {
            edges {
              node {
                quantity
                lineItem {
                  title
                  variant {
                    sku
                  }
                }
              }
            }
          }
        }
        shippingAddress {
          address1
          address2
          city
          country
          countryCodeV2
          province
          provinceCode
          zip
        }
        totalShippingPriceV2 {
          amount
        }
        shippingDiscountAllocations {
          allocatedAmount {
            amount
          }
        }
        lineItems(first: 250) {
          edges {
            node {
              currentQuantity
              discountAllocations {
                allocatedAmount {
                  amount
                }
              }
              discountedTotalPrice {
                amount
              }
              originalTotalPrice {
                amount
              }
              quantity
              title
              variant {
                sku
              }
            }
          }
        }
      }
    }
  }
`
